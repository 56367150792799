import { render, staticRenderFns } from "./u-tabs-pill.vue?vue&type=template&id=7675d443&scoped=true&"
import script from "./u-tabs-pill.vue?vue&type=script&lang=js&"
export * from "./u-tabs-pill.vue?vue&type=script&lang=js&"
import style0 from "./u-tabs-pill.vue?vue&type=style&index=0&id=7675d443&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7675d443",
  null
  
)

export default component.exports