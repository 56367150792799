<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-download">
        <g clip-path="url(#clip0_2117_9545)">
            <path d="M19.5 9H15.5V3H9.5V9H5.5L12.5 16L19.5 9ZM5.5 18V20H19.5V18H5.5Z"/>
        </g>
        <defs>
            <clipPath id="clip0_2117_9545">
                <rect width="24" height="24" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.icon-download {
    width: 18px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .icon-download {
        width: 24px;
    }
}
@media (min-width:1200px){
}

</style>