<template>
    <v-card tile tag="aside" width="100%" class="overflow-hidden">
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <aside-navigation v-bind="{ items }" />
    </v-card>
</template>

<script>
import { FAQ_TYPES } from "@/assets/variables";

import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

const items = [
    { text: "공지사항", to: { path: "/center/notice" } },
    {
        text: "자주묻는 질문",
        children: [
            {
                text: "전체보기",
                to: { path: "/center/faq" },
            },

            ...Object.values(FAQ_TYPES).map(({ text, value }) => ({
                text,
                to: {
                    path: "/center/faq",
                    query: { type: value },
                },
            })),
        ],
    },
    {
        text: "문의하기",
        children: [
            {
                text: "전체보기",
                to: { path: "/center/inquiry" },
            },
            {
                text: "제품관련문의",
                to: { path: "/center/inquiry" },
            },
            {
                text: "배송·설치 서비스",
                to: { path: "/center/inquiry" },
            },
            {
                text: "욕실·주방진단",
                to: { path: "/center/inquiry" },
            },
            {
                text: "리모델링 컨설팅",
                to: { path: "/center/inquiry" },
            },
            {
                text: "기타",
                to: { path: "/center/inquiry" },
            },
        ],
    },
    {
        text: "개인 & 기업회원 서비스",
        children: [
            {
                text: "개인회원 서비스",
                to: { path: "/center/individual-service" },
            },
            {
                text: "기업회원 서비스",
                to: { path: "/center/corporate-service" },
            },
        ],
    },
    {
        text: "JFL 가이드",
        children: [
            {
                text: "첫 구매 가이드",
                to: { path: "/center/first-buying-guide" },
            },
            {
                text: "필독 가이드",
                to: { path: "/center/must-read-guide" },
            },
            {
                text: "설치 서비스",
                to: { path: "/center/installation-service" },
            },
        ],
    },
    {
        text: "JFL 케어",
        children: [
            {
                text: "출장 컨설팅",
                to: { path: "/center/business-trip-consulting" },
            },
            {
                text: "온라인 컨설팅",
                to: { path: "/center/online-consulting" },
            },
            {
                text: "딜리버리 설치 서비스",
                to: { path: "/center/delivery-installation-service" },
            },
            {
                text: "전담 수리 서비스",
                to: { path: "/center/dedicated-repair-service" },
            },
            {
                text: "안심 상담",
                to: { path: "/center/consultation" },
            },
        ],
    },
    { text: "대량구매요청", to: { path: "/center/bulk-purchase" } },
    // { text: "회사소개", to: "/center/about" },
    // { text: "이용약관", to: "/terms" },
    // { text: "개인정보처리방침", to: "/privacy-policy" },
    // { text: "이메일무단수집거부", to: "/center/email-policy" },
];

export default {
    components: {
        AsideNavigation,
    },
    data: () => ({
        items,
    }),
};
</script>
