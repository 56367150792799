<template>
    <client-page class="app--client">
        <template #subHead>
            <sub-visual sh="Library">
                <template #subTab>
                    <library-sub-tabs />
                </template>
            </sub-visual>
        </template>
        <v-row class="my-md-n20px">
            <template v-for="item in items">
                <v-col cols="6" lg="3" class="py-md-20px" :key="item._id">
                    <catalog-item v-bind="{ item }" />
                </v-col>
            </template>
        </v-row>

        <div class="mt-30px mt-md-60px">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </client-page>
</template>
<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import LibrarySubTabs from "@/components/client/sub/library-sub-tabs.vue";
import CatalogItem from "@/components/client/shop/catalogues/catalog-item.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        LibrarySubTabs,
        CatalogItem,
    },
    data: () => ({
        limit: 8,

        summary: { totalCount: 0 },
        catalogues: [],

        loading: false,
    }),
    computed: {
        page() {
            return +this.$route.query.page || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        searchKey() {
            return this.$route.query.searchKey;
        },
        searchValue() {
            return this.$route.query.searchValue;
        },
        items() {
            return this.catalogues;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.catalogues = [];
            this.search();
        },
        async search() {
            const { skip, limit, searchKey, searchValue } = this;
            const { summary, catalogues } = await api.v1.shop.catalogues.gets({
                headers: { skip, limit },
                params: { code: "catalogue", searchKey, searchValue },
            });
            this.summary = summary;
            this.catalogues = catalogues;
        },
    },
};
</script>

<style></style>
