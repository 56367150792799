<template>
    <v-tabs v-model="tabs" v-bind="{ ...attrs_tabs_common, ...$attrs }" hide-slider class="v-tabs--inline">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tabs>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

export default {
    props: {
        value: { type: [String, Number] },
    },
    components: {},
    data: () => {
        return {
            attrs_tabs_common,
        };
    },
    computed: {
        tabs: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tabs--inline {
    --tabs-height: 38px;
    &.v-tabs {
        ::v-deep{
            .v-tabs-bar{
                background-color: transparent !important;
            }
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-tabs--inline {
        --tabs-height: 48px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
