var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tabs-pill', [_c('u-tab-pill', {
    attrs: {
      "to": "/library/software"
    }
  }, [_vm._v("소프트웨어")]), _c('u-tab-pill', {
    attrs: {
      "to": "/library/catalog"
    }
  }, [_vm._v("카탈로그")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }