<template>
    <v-list flat no-action class="aside-list">
        <template v-for="item in items">
            <v-list-item v-if="!item.children" :key="item.text" :to="item.to" exact exact-active-class="aside-list--active">
                <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
            <v-list-group v-else no-action :value="true" :key="item.text">
                <template v-slot:activator>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </template>

                <v-list-item v-for="child in item.children" :key="child.text" :to="child.to" exact exact-active-class="aside-list--active">
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                </v-list-item>
            </v-list-group>
        </template>
    </v-list>
</template>

<script>
export default {
    props: {
        items: { type: Array, default: () => [] },
    },
};
</script>

<style lang="scss" scoped>
.aside-list {
    padding: 0;
    ::v-deep {
        .v-list-item {
            min-height: 28px;
            font-size: 14px !important;
            padding: 0 16px !important;
            &__title {
                font-size: inherit;
            }
        }
        .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            color: var(--v-grey-base) !important;
        }
        > .v-list-item,
        > .v-list-group {
            padding: 10px 0;
            border-bottom: 1px solid var(--v-grey-lighten4);
        }
        > .v-list-item,
        > .v-list-group > .v-list-group__header {
            font-weight: 700;
            color: var(--v-grey-darken4) !important;
            font-size: 16px !important;
        }
        > .v-list-group {
            > .v-list-group__header {
                pointer-events: none;
                cursor: default;
                .v-icon {
                    display: none;
                }
            }
            .aside-list {
                &--active {
                    font-weight: 400 !important;
                    color: var(--v-grey-darken4) !important;
                    text-decoration: underline;
                }
            }
        }
        > .v-list-item {
            min-height: 54px;
        }
    }

    &--active {
        font-weight: 700 !important;
        color: var(--v-primary-base) !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .aside-list {
        ::v-deep {
            > .v-list-item,
            > .v-list-group {
                padding: 20px 0;
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
