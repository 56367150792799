var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--client",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "bright Academy"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-n40px mt-md-n80px"
  }, [_vm._l(_vm.academyCategories, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return [_c('academy-category-list', {
      key: value,
      staticClass: "'mt-40px mt-md-80px",
      attrs: {
        "code": "bright-academy",
        "title": text,
        "category": value
      }
    })];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }