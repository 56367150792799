var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "문의하기",
      "subTitle": "전체보기"
    }
  }, [_vm.accessToken ? _c('page-section', {
    staticClass: "py-0"
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }