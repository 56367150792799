var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-sheet', {
    attrs: {
      "fullscreen": "",
      "content-class": "mw-100 h-100",
      "max-width": "100%",
      "width": "100%"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (bind) {
        return [_vm._t("activator", null, null, bind)];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "absolute": "",
      "top": "",
      "right": "",
      "icon": "",
      "text": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-close')], 1), _vm.board.image ? [_c('v-sheet', {
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "max-height": "90vh",
      "max-width": "80vw",
      "width": "max-content"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.board.image
    }
  })])] : [_c('v-expand-transition', [_c('v-responsive', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsVideo,
      expression: "showsVideo"
    }],
    attrs: {
      "max-width": "1032",
      "aspect-ratio": 1032 / 580
    }
  }, [_vm.videoId ? _c('youtube', _vm._b({
    attrs: {
      "fitParent": ""
    },
    on: {
      "ready": function ($event) {
        _vm.showsVideo = true;
      }
    }
  }, 'youtube', {
    videoId: _vm.videoId
  }, false)) : _c('video', {
    ref: "video-player",
    staticClass: "video-js vjs-theme-city",
    attrs: {
      "width": "1032",
      "controls": ""
    }
  })], 1)], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-progress-circular', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.showsVideo,
      expression: "!showsVideo"
    }],
    attrs: {
      "width": "5",
      "size": "200",
      "color": "white",
      "indeterminate": ""
    }
  })], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }