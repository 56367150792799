var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "academy-video",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "auto"
    }
  }, [_c('v-responsive', {
    staticClass: "academy-video__video rounded",
    attrs: {
      "aspect-ratio": 588 / 330,
      "max-width": "588"
    }
  }, [_c('iframe', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "src": "https://www.youtube.com/embed/KrDkvpN6dD8?si=xlKhk_NN_GmzPre_",
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1), _c('v-col', {
    staticClass: "mt-8px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": ""
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm font-weight-medium"
  }, [_vm._v(" Seminar video title Seminar video title Seminar video title ")])])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }