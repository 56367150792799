var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-download",
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_2117_9545)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M19.5 9H15.5V3H9.5V9H5.5L12.5 16L19.5 9ZM5.5 18V20H19.5V18H5.5Z"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_2117_9545"
    }
  }, [_c('rect', {
    attrs: {
      "width": "24",
      "height": "24",
      "transform": "translate(0.5)"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }