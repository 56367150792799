<template>
    <client-page>
        <center-layout title="개인 & 기업회원 서비스" subTitle="기업회원 서비스">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">기업회원 등급 및 멤버십 혜택</h3>
                        <p class="txt txt--sm txt--dark mt-8px">기업 회원만을 위한 특별한 혜택을 누려보세요. 창업 예정이시거나 사업을 운영 중이신 모든 고객분들은 신청이 가능합니다. 성공적인 비지니스를 위해 조이포라이프가 좋은 파트너가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.</p>
                        <div class="btn-wrap">
                            <v-btn v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">기업회원 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">합리적인 가격에 만족스러운 서비스까지.</div>
                                    <div class="txt txt--xs">조이포라이프는 기업전용 회선이 있더라구요. 급하게 주문할 제품을 전화 한통으로 신속하게 해결했습니다. 상담원 역시 친절히 안내해주셔서 아주 만족스럽게 설치 할 수 있었습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>바로세라</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>조이라이크</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 제품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>데코타일</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">아주 좋아요</div>
                                    <div class="txt txt--xs">조이포라이프 몰은 기업할인 외에도 적립금, 사은품도 챙겨줘서 항상 잘 이용하고 있습니다. 대량구매를 원하시는 분들은 아주 좋은 서비스를 받으실 수 있을거라 장담합니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>바른가구</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">기업회원을 위한 특별한 서비스</h4>
                </div>

                <v-row>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">기업회원만의 특별한 할인</div>
                                    <div class="txt txt--xs">더 많은 혜택을 드릴 수 있도록 수량, 건수에 따라 더 많은 할인을 제공해 드립니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">기업회원 적립금 지급</div>
                                    <div class="txt txt--xs">수량 및 건수에 따라 차등지급되며 총 금액에 따라 적립금도 사용 가능합니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">전국 출장컨설팅 매칭</div>
                                    <div class="txt txt--xs">서울/경기 지역외 출장서비스를 원하시면 상담사를 통해 언제든 확인 가능합니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">기업회원 전담팀 운영</div>
                                    <div class="txt txt--xs">신속한 상담과 고객만족을 위해 기업회원 직통 CS 팀을 운영하고 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">무상 설치 기술 이전</div>
                                    <div class="txt txt--xs">효율적인 고객응대를 위해 자사 전담팀이 기술관련 내용을 충실히 전해드리고 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">원스탑(One-Stop) 서비스</div>
                                    <div class="txt txt--xs">자사 웹사이트를 통해 대량구매 및 단품구매 모두 기업회원을 위한 서비스를 제공 받으실 수 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">기업회원 등록 방법</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm txt--dark">회원가입 시 기업회원으로 가입해 주세요.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm txt--dark">회원가입 시 사업자등록증을 필히 첨부해 주세요. 개인회원가입 후 기업회원으로 변경을 원하실 땐 게시판을 활용해 주세요.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm txt--dark">게시글이 확인되면 전환 여부를 댓글과 문자로 알려드립니다.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import IconCheckBox from "@/components/client/icons/icon-check-box.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        IconCheckBox,
    },
    data() {
        return {
            btn_primary,
        };
    },
};
</script>
