var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--client",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "bright Academy"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('academy-list', _vm._b({}, 'academy-list', {
    category: _vm.category
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }