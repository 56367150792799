<template>
    <academy-view v-bind="{ board }">
        <template #activator="{ attrs, on }">
            <v-card v-bind="{ ...$attrs, ...attrs }" tile class="v-card--none-active-bg" v-on="on">
                <v-img :src="board.thumb" :aspect-ratio="282 / 158" max-width="282" class="w-100 rounded" />
                <div class="pt-12px pt-md-20px">
                    <p class="page-text font-weight-medium text-truncate-2">{{ board.subject }}</p>
                </div>
            </v-card>
        </template>
    </academy-view>
</template>

<script>
import AcademyView from "./academy-view.vue";

export default {
    components: {
        AcademyView,
    },
    props: {
        board: { type: Object, default: () => ({}) },
    },
};
</script>

<style></style>
