<template>
    <v-tab v-bind="$attrs" class="v-tab--pill" @click="$emit('click')">
        <span><slot /></span>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--pill {
    &.v-tab { 
        --tab-font-size: 16px;
        --tab-active-font-weight: 500;
        --tab-color: var(--v-grey-base);
        --tab-padding: 0 10px;

        border-radius: 999px;
        background-color: var(--v-grey-lighten5);
            
        &:not(:last-child){
            margin-right: 10px;
        }

        &::before{
            display: none;
        }
        &.v-tab--active{
            color: #fff;
            background-color: var(--v-primary-base);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-tab--pill {
        &.v-tab {        
            --tab-font-size: 22px;
            --tab-padding: 0 24px ;
            
            &:not(:last-child){
                margin-right: 12px;
            }
        }
    }
}
@media (min-width:1024px){
    .v-tab--pill {
        &.v-tab:hover {        
            color: #fff !important;
            background-color: var(--v-primary-base);
        }
    }
}
@media (min-width:1200px){
}

</style>
