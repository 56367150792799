<template>
    <u-tabs-pill>
        <u-tab-pill to="/library/software">소프트웨어</u-tab-pill>
        <u-tab-pill to="/library/catalog">카탈로그</u-tab-pill>
    </u-tabs-pill>
</template>

<script>
import UTabsPill from "@/components/dumb/u-tabs-pill.vue";
import UTabPill from "@/components/dumb/u-tab-pill.vue";
export default {
    components: {
        UTabsPill,
        UTabPill
    },
}
</script>

<style>

</style>