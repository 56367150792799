<template>
    <component :is="SkinComponent" v-bind="{ ...$attrs, code, category, forms, summary, page, limit, loading }" />
</template>

<script>
import api from "@/api";

import PaginationComponent from "@/components/client/pagination-component.vue";

export default {
    components: {
        PaginationComponent,
    },
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
        category: { type: String, default: null },
    },
    data: () => ({
        forms: [],
        summary: { totalCount: 0 },

        limit: 10,

        loading: false,
    }),
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/list.vue`);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.isMine = true;

            query.code = this.code;
            if (!query.code) delete query.code;

            query.category = this.category;
            if (!query.category) delete query.category;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        init() {
            this.search(false);
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, forms } = await api.v1.forms.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.forms = forms;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
