var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('academy-view', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-card', _vm._g(_vm._b({
          staticClass: "v-card--none-active-bg",
          attrs: {
            "tile": ""
          }
        }, 'v-card', Object.assign({}, _vm.$attrs, attrs), false), on), [_c('v-img', {
          staticClass: "w-100 rounded",
          attrs: {
            "src": _vm.board.thumb,
            "aspect-ratio": 282 / 158,
            "max-width": "282"
          }
        }), _c('div', {
          staticClass: "pt-12px pt-md-20px"
        }, [_c('p', {
          staticClass: "page-text font-weight-medium text-truncate-2"
        }, [_vm._v(_vm._s(_vm.board.subject))])])], 1)];
      }
    }])
  }, 'academy-view', {
    board: _vm.board
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }