var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "tit mb-30px mb-md-60px"
  }, [_vm._v(_vm._s(_vm.title))]), _c('v-row', {
    staticClass: "my-md-n20px"
  }, [_vm._l(_vm.boards, function (board) {
    return [_c('v-col', {
      key: board._id,
      staticClass: "py-md-20px",
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('academy-item', _vm._b({}, 'academy-item', {
      board
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "mt-30px mt-md-60px"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }