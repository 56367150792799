<template>
    <div>
        <h3 class="tit mb-30px mb-md-60px">{{ title }}</h3>

        <v-row class="my-md-n20px">
            <!-- S: item -->
            <template v-for="board in boards">
                <v-col cols="6" md="3" class="py-md-20px" :key="board._id">
                    <academy-item v-bind="{ board }" />
                </v-col>
            </template>
            <!-- E: item -->
        </v-row>

        <div class="mt-30px mt-md-60px">
            <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </div>
    </div>
</template>

<script>
import api from "@/api";

import AcademyItem from "@/components/client/center/academy/academy-item.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        AcademyItem,
        BtnPrimary,
    },
    props: {
        category: { type: String, default: null },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        limit: 20,

        loading: false,
    }),
    computed: {
        title() {
            return (this.category || "")
                .split("-")
                .map(([char0, ...chars]) => [char0.toUpperCase(), ...chars].join(""))
                .join(" ");
        },
        page() {
            return +(this.$route.query.page || 1);
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            const { code, category } = this;
            const params = { code, category };

            if (!code) delete params.code;
            if (!category) delete params.category;

            return params;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.loading = false;
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, boards } = await api.v1.boards.gets({
                    headers: { skip, limit },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
