var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.$attrs, {
    code: _vm.code,
    category: _vm.category,
    forms: _vm.forms,
    summary: _vm.summary,
    page: _vm.page,
    limit: _vm.limit,
    loading: _vm.loading
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }