<template>
    <client-page class="app--client">
        <template #subHead>
            <sub-visual sh="bright Academy" />
        </template>

        <!-- <academy-head-video /> -->

        <div class="mt-n40px mt-md-n80px">
            <template v-for="{ text, value } in academyCategories">
                <academy-category-list code="bright-academy" :title="text" :category="value" :key="value" class="'mt-40px mt-md-80px" />
            </template>
        </div>
    </client-page>
</template>
<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import AcademyHeadVideo from "@/components/client/center/academy/academy-head-video.vue";
import AcademyCategoryList from "@/components/client/center/academy/academy-category-list.vue";

import { mapGetters } from "vuex";

export default {
    components: {
        ClientPage,
        SubVisual,
        AcademyHeadVideo,
        AcademyCategoryList,
    },
    computed: {
        ...mapGetters(["academyCategories"]),
    },
};
</script>

<style></style>
