<template>
    <v-card color="grey lighten-5" class="academy-video">
        <div class="pa-20px pa-md-30px pa-lg-40px">
            <v-row align="center">
                <v-col cols="12" md="6" xl="auto">
                    <v-responsive :aspect-ratio="588 / 330" max-width="588" class="academy-video__video rounded">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/KrDkvpN6dD8?si=xlKhk_NN_GmzPre_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                    </v-responsive>
                </v-col>
                <v-col cols="12" md="6" xl="" class="mt-8px mt-md-0">
                    <h4 class="tit tit--sm font-weight-medium">
                        Seminar video title Seminar video title Seminar video title
                    </h4>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
.academy-video{
    --card-border-radius: 6px;
    background-image: url(/images/ci-white.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto 80px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .academy-video{
        --card-border-radius: 12px;
        background-size: auto 120px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .academy-video{
        &__video{
            min-width: 588px;
        }
    }
}

</style>