var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--client",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Library"
          },
          scopedSlots: _vm._u([{
            key: "subTab",
            fn: function () {
              return [_c('library-sub-tabs')];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "my-md-n20px"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item._id,
      staticClass: "py-md-20px",
      attrs: {
        "cols": "6",
        "lg": "3"
      }
    }, [_c('catalog-item', _vm._b({}, 'catalog-item', {
      item
    }, false))], 1)];
  })], 2), _c('div', {
    staticClass: "mt-30px mt-md-60px"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }