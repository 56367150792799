<template>
    <client-page>
        <!-- 개발확인 : 전체보기부분 확인부탁드립니다. -->
        <center-layout title="문의하기" subTitle="전체보기">
            <page-section v-if="accessToken" class="py-0">
                <!-- <template v-if="$route.query._form === undefined">
                    <form-list v-bind="{ code, category, skin }" />
                </template>
                <template v-if="$route.query._form === null">
                    <form-input v-bind="{ code, category, skin }" />
                </template> -->
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";

import FormList from "@/components/client/form/form-list.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        CenterLayout,

        FormList,
        FormInput,
    },
    data: () => ({
        skin: "normal",
        code: "normal",
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        category() {
            return this.$route.query.category;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
};
</script>
