<template>
    <svg width="28" height="29" viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-close">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.06055 0.499023L27.5598 26.9982L26.4991 28.0589L-0.000113279 1.55968L1.06055 0.499023Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0605469 26.499L26.5598 -0.00019008L27.6204 1.06047L1.12121 27.5597L0.0605469 26.499Z" />
    </svg>

</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-check {
    width: 28px;
}
</style>
