var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "개인 & 기업회원 서비스",
      "subTitle": "기업회원 서비스"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("기업회원 등급 및 멤버십 혜택")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("기업 회원만을 위한 특별한 혜택을 누려보세요. 창업 예정이시거나 사업을 운영 중이신 모든 고객분들은 신청이 가능합니다. 성공적인 비지니스를 위해 조이포라이프가 좋은 파트너가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px"
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기업회원 리얼 고객 후기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("합리적인 가격에 만족스러운 서비스까지.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프는 기업전용 회선이 있더라구요. 급하게 주문할 제품을 전화 한통으로 신속하게 해결했습니다. 상담원 역시 친절히 안내해주셔서 아주 만족스럽게 설치 할 수 있었습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("바로세라")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("신속한 처리 만족합니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("조이라이크")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("늘 애용하는 조이포라이프!!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문한 제품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("데코타일")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("아주 좋아요")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 몰은 기업할인 외에도 적립금, 사은품도 챙겨줘서 항상 잘 이용하고 있습니다. 대량구매를 원하시는 분들은 아주 좋은 서비스를 받으실 수 있을거라 장담합니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("바른가구")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기업회원을 위한 특별한 서비스")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("기업회원만의 특별한 할인")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("더 많은 혜택을 드릴 수 있도록 수량, 건수에 따라 더 많은 할인을 제공해 드립니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("기업회원 적립금 지급")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("수량 및 건수에 따라 차등지급되며 총 금액에 따라 적립금도 사용 가능합니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("전국 출장컨설팅 매칭")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("서울/경기 지역외 출장서비스를 원하시면 상담사를 통해 언제든 확인 가능합니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("기업회원 전담팀 운영")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("신속한 상담과 고객만족을 위해 기업회원 직통 CS 팀을 운영하고 있습니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("무상 설치 기술 이전")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("효율적인 고객응대를 위해 자사 전담팀이 기술관련 내용을 충실히 전해드리고 있습니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("원스탑(One-Stop) 서비스")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("자사 웹사이트를 통해 대량구매 및 단품구매 모두 기업회원을 위한 서비스를 제공 받으실 수 있습니다.")])])])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("기업회원 등록 방법")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("회원가입 시 기업회원으로 가입해 주세요.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("회원가입 시 사업자등록증을 필히 첨부해 주세요. 개인회원가입 후 기업회원으로 변경을 원하실 땐 게시판을 활용해 주세요.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("게시글이 확인되면 전환 여부를 댓글과 문자로 알려드립니다.")])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }