<template>
    <client-page class="app--client">
        <template #subHead>
            <sub-visual sh="bright Academy" />
        </template>
        <academy-list v-bind="{ category }" />
    </client-page>
</template>
<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import AcademyList from "@/components/client/center/academy/academy-list.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        AcademyList,
    },
    props: {
        category: { type: String, default: null },
    },
};
</script>

<style></style>
