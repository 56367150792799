var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual",
    class: {
      'sub-visual--sub-tab': this.$slots['subTab']
    }
  }, [_c('v-container', [_c('tit-wrap-dot', {
    staticClass: "mb-0",
    attrs: {
      "title": _vm.sh,
      "large": ""
    }
  }), this.$slots['subTab'] ? _c('div', {
    staticClass: "mt-30px mt-md-60px"
  }, [_vm._t("subTab")], 2) : _vm._e()], 1), _c('v-responsive', {
    staticClass: "sub-visual__bg",
    attrs: {
      "aspect-ratio": 1920 / 760
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }