var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap--line"
  }, [_c('div', {
    staticClass: "pb-12px pb-md-20px"
  }, [_c('v-row', _vm._b({
    staticClass: "row--sm"
  }, 'v-row', {
    align: _vm.align
  }, false), [_c('div', {
    staticClass: "col col-auto"
  }, [this.$slots['titleContents'] ? _c('div', [_vm._t("titleContents")], 2) : _c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s(_vm.title))])]), this.$slots['default'] ? _c('v-spacer') : _vm._e(), this.$slots['default'] ? _c('div', {
    staticClass: "col col-auto"
  }, [_vm._t("default")], 2) : _vm._e()], 1)], 1), !_vm.noDivider ? _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }