<template>
    <div>
        <div class="mb-20px mb-md-48px">
            <v-row align="center">
                <v-col cols="12" md="">
                    <h3 class="tit">{{ title }}</h3>
                </v-col>
                <v-col cols="12" md="auto">
                    <btn-primary :to="`/academy/${category}`" />
                </v-col>
            </v-row>
        </div>
        <v-row>
            <!-- S: item -->
            <template v-for="board in boards">
                <v-col cols="6" md="3" :key="board._id">
                    <academy-item v-bind="{ board }" />
                </v-col>
            </template>
            <template v-if="boards.length == 0">
                <v-col> 등록된 영상이 없습니다 </v-col>
            </template>
            <!-- E: item -->
        </v-row>
    </div>
</template>

<script>
import api from "@/api";

import AcademyItem from "@/components/client/center/academy/academy-item.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

export default {
    components: {
        AcademyItem,
        BtnPrimary,
    },
    props: {
        code: { type: String, default: "bright-academy" },
        title: { type: String, default: null },
        category: { type: String, default: null },
    },
    data: () => ({
        boards: [],
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        params() {
            const { code, category } = this;
            const params = { code, category };

            if (!code) delete params.code;
            if (!category) delete params.category;

            return params;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.loading = false;
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { params } = this;
                const { summary, boards } = await api.v1.boards.gets({
                    headers: { skip: 0, limit: 4 },
                    params,
                });

                this.boards = boards;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
