var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mb-20px mb-md-48px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('btn-primary', {
    attrs: {
      "to": `/academy/${_vm.category}`
    }
  })], 1)], 1)], 1), _c('v-row', [_vm._l(_vm.boards, function (board) {
    return [_c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('academy-item', _vm._b({}, 'academy-item', {
      board
    }, false))], 1)];
  }), _vm.boards.length == 0 ? [_c('v-col', [_vm._v(" 등록된 영상이 없습니다 ")])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }